import React from "react";
import ecgLogo from "../resources/images/ecg_wko-500x250.jpg";
import dsgvo from "../resources/datenschutzerklaerung.pdf";

export default class Projects extends React.Component {
  render() {
    return (
      <div className="row">
        <div className="container">
          <div className="row pd-t-45">
            <div className="col-12 text-left">
              <h4 className="secondary">IMPRESSUM</h4>
              <h1 style={{ margin: "5px 0px" }} className="primary extended">
                Sollen wir bauen? Oder zumindest mal reden?
              </h1>
            </div>
            <div className="col-12 text-left">
              <p
                style={{
                  maxWidth: "800px",
                  display: "inline-block",
                  marginTop: "10px",
                }}
              >
                Wir freuen uns darauf, Sie und Ihr Bauvorhaben kennen zu lernen!
              </p>
            </div>
          </div>
          <div className="row pd-t-45">
            <div className="col-12 col-sm-6">
              <h2>E.K. Invest Bau GmbH</h2>
              <p>
                Am Schöpfwerk 5 Top 1, Österreich
                <br />
                Tel/Fax: <b>+43 1 982 27 24</b>
                <br />
                Mobil: <b>+43 660 13 11 770</b>
                <br />
                E-Mail: <b>office@ekinvestbau.at</b>
                <br />
                Internet:{" "}
                <a href="http://ekinvestbau.at">
                  <b>www.ekinvestbau.at</b>
                </a>
                <br />
              </p>
            </div>
            <div className="col-12 col-sm-6">
              <h2>Rechtliche Informationen</h2>
              <p>
                Handelsrechtlicher Geschäftsführer: <b>Elvir Kasumovic</b>
                <br />
                Gewerberechtlicher Geschäftsführer: <b>Ing. Gerhard Rath</b>
                <br />
                Rechtsform: <b>Gesellschaft mit beschränkter Haftung</b>
                <br />
                Kammmer: <b>Wirtschaftskammer Wien</b>
                <br />
                gesetzliche Berufsbezeichnung: <b>
                  Baumeister, Österreich
                </b>{" "}
                <a href="https://www.ris.bka.gv.at/">
                  (Baumeister gemäß § 99 GewO 1994)
                </a>
                <br />
                Firmenbuchnummer: <b>372641b</b>
                <br />
                Firmengericht: <b>Handelsgericht Wien</b>
                <br />
                UID-Nummer: <b>ATU 67104135</b>
                <br />
                <a href={dsgvo}>Datenschutzerklärung</a>
              </p>
            </div>
            {/* <div className="col-12 col-sm-6">
              <ul className="imp-list">
                <li>
                  <i className="fa fa-building-o" />{" "}
                  <span>Maria-Kuhn-Gasse 6/2/B1, 1100 Wien</span>
                </li>
                <li>
                  <i className="fa fa-fax" /> <span>+43 (0) 1 982 27 24</span>
                </li>
                <li>
                  <i className="fa fa-phone" />{" "}
                  <span>+43 (0) 660 131 17 70</span>
                </li>
                <li>
                  <i className="fa fa-envelope" />{" "}
                  <span>office@ekinvestbau.at</span>
                </li>
              </ul>
            </div> */}
          </div>
          <div>
            <a href="https://www.wkoecg.at/Web/Ecg.aspx?FirmaID=736ba6b6-1d0c-4548-b70d-8c7e6875baa8">
              <img src={ecgLogo} alt="ecg logo" style={{ maxWidth: "150px" }} />
            </a>
          </div>
        </div>
      </div>
    );
  }
}
